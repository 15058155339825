import React from 'react'
import PropTypes from 'prop-types'
import Animation from './animation'
import CheckLink from './checkLink'
import Img from 'gatsby-image'

const Hero = ({ animationWidth, slice }) => {

  return (
    <>
      <div className={`component-${slice.slice_type} relative z-10 pt-24 pb-1 bg-gradient-to-r from-brand-light-blue to-brand-dark-blue`}>
        <div className='max-w-screen-xl mx-auto'>
          <div className='md:flex flex-row'>
            <div className={`w-full flex-1 px-4 mt-10 sm:my-12 md:my-16 lg:mt-20 text-white ${(slice.primary.image.fluid || slice.primary.animation_name) && ('xl:my-28 lg:mb-0')}`}>
              {slice.primary.title.html && (
                <div dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
              )}
              {slice.primary.text.html && (
                <div className='text-xl sm:text-2xl' dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
              )}
              {slice.primary.button_link.url && (
                <div className='mt-5 sm:mt-8 sm:flex sm:justify-start'>
                  <CheckLink className='rounded-md shadow-lg flex items-center justify-center px-8 py-3 border border-transparent text-xl sm:text-2xl leading-6 font-medium transform duration-300 ease-in-out transition focus:outline-none focus:ring md:px-10 md:py-4 bg-white text-brand-blue hover:bg-blue-100 hover:shadow-lg' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink>
                </div>
              )}
            </div>
            {(slice.primary.image.fluid || slice.primary.animation_name) && (
              <div className='flex-1 hidden sm:block'>
                {slice.primary.image.fluid ? (
                  <div className='-mb-24 xl:-mb-40 xl:-mb-48'>
                    <Img fluid={slice.primary.image.fluid} alt={slice.primary.image.alt}/>
                  </div>
                ):(
                  <div className={animationWidth === 'full' ? ('animation-full absolute right-0'):('px-20 md:px-0 lg:px-20 -mb-24 xl:-mb-48')}>
                    <Animation animationName={slice.primary.animation_name} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='relative bg-gradient-to-r from-brand-light-blue to-brand-dark-blue z-0'>
        <svg viewBox='0 0 1428 174' xmlns='http://www.w3.org/2000/svg'>
          <g fill='#FFF' fillRule='nonzero'>
            <path d='M-2 44c90.728.928 147.913 27.188 291.91 59.912 95.998 21.816 251.695 29.423 467.09 22.82-289.664 73.522-542.664 70.936-759-7.759' opacity='.1'/>
            <path d='M98 148.708c177.413-32.473 326.148-52.183 446.204-59.13 120.055-6.945 264.32-3.78 432.796 9.496-47.93 1.049-168.697 19.772-362.3 56.17-193.603 36.397-365.837 34.219-516.7-6.536z' opacity='.1'/>
            <path d='M1044 95.652c84.83-22.323 233.083-34.044 393-11.486V164c-167.828-42.056-298.828-64.84-393-68.348z' opacity='.2'/>
            <g>
              <path d='M-3.543 110.035c56.629 19.163 97.751 31.774 123.365 37.83 57.632 13.63 110.473 18.425 148.211 21.594 39.322 3.3 124.602 2.342 188.992-1.796 25.735-1.653 57.702-5.291 95.901-10.911 38.821-6.156 65.446-10.744 79.873-13.761 27.114-5.667 75.702-17.488 90.806-20.863 52.865-11.811 91.234-23.596 128.719-30.224 66.365-11.735 99.352-13.382 154.861-15.472 49.52 1.045 86.205 2.697 110.051 4.955 40.467 3.832 87.385 12.434 114.164 16.917 50.455 8.444 118.951 25.128 205.486 50.05l.305 31.998-1440.07-.321-.664-69.996z'/>
            </g>
          </g>
        </svg>
      </div>
    </>
  )
}

Hero.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Hero
