import React from 'react'
import PropTypes from 'prop-types'
import Animation from './animation'
import BannerWithCaption from './bannerWithCaption'
import CardDeck from './cardDeck'
import Code from './code'
import Contact from './contact'
import Counter from './counter'
import DirectDebit from './directDebit'
import Hero from './hero'
import Image from './image'
import Quote from './quote'
import Text from './text'
import TextWithButton from './textWithButton'
import Video from './video'

const SliceList = ({ animationWidth, customer, price, slice }) => {

  return (
    <>
      {slice.slice_type === 'animation' && (
        <div className='max-w-5xl mx-auto py-8 px-5 sm:px-6 md:py-16 lg:p-8 '>
          <Animation animationName={slice.primary.name} />
        </div>
      )}
      {slice.slice_type === 'banner_with_caption' && (
        <div className='max-w-screen-xl mx-auto py-8 px-4 sm:px-6 md:py-16 lg:p-8 text-gray-800'>
          <BannerWithCaption slice={slice} />
        </div>
      )}
      {slice.slice_type === 'card_deck' && (
        <div className='max-w-screen-xl mx-auto py-8 px-4 sm:px-6 md:py-16 lg:p-8 text-gray-800'>
          <CardDeck slice={slice} />
        </div>
      )}
      {slice.slice_type === 'code' && (
        <div className='max-w-screen-xl mx-auto py-8 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-16'>
          <Code slice={slice} />
        </div>
      )}
      {slice.slice_type === 'contact' && (
        <div className={`component-${slice.slice_type} max-w-screen-xl mx-auto py-8 lg:pt-0 sm:px-4 relative z-10`}>
          <Contact slice={slice} />
        </div>
      )}
      {slice.slice_type === 'counter' && (
        <div className='max-w-screen-xl mx-auto py-8 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-16'>
          <Counter slice={slice} />
        </div>
      )}
      {slice.slice_type === 'direct_debit' && (
        <div className='max-w-3xl mx-auto py-8 px-4 sm:px-6 md:py-0 lg:px-8 -mt-20 lg:-mt-32 relative z-10 mb-20'>
          <div className='relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col'>
            <DirectDebit customer={customer} price={price} />
          </div>
        </div>
      )}
      {slice.slice_type === 'hero' && (
        <Hero slice={slice} animationWidth={animationWidth}/>
      )}
      {slice.slice_type === 'image' && (
        <Image slice={slice} />
      )}
      {slice.slice_type === 'quote' && (
        <div className='bg-brand-blue text-white'>
          <div className='max-w-6xl mx-auto py-8 px-12 sm:px-6 md:py-16 lg:px-8'>
            <Quote slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'text' && (
        <div className='bg-white max-w-5xl mx-auto py-8 px-5 sm:px-6 md:py-16 lg:p-8 text-gray-800'>
          <Text slice={slice} />
        </div>
      )}
      {slice.slice_type === 'text_with_button' && (
        <div className='shadow-lg'>
          <div className='bg-white max-w-5xl mx-auto py-8 px-5 sm:px-6 md:py-16 lg:p-8 text-gray-800'>
            <TextWithButton slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'video' && (
        <div className='bg-gray-300 text-gray-700'>
          <div className='max-w-screen-xl mx-auto py-8 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-16'>
            <Video slice={slice} />
          </div>
        </div>
      )}
    </>
  )
}

SliceList.propTypes = {
  animationWidth: PropTypes.object,
  customer: PropTypes.string,
  price: PropTypes.string,
  slice: PropTypes.object.isRequired,
}

export default SliceList
