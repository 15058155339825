import React from 'react'
import CountUp from 'react-countup'
import get from 'lodash/get'

const Counter = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type}`}>
      <div dangerouslySetInnerHTML={{__html:get(slice.primary, 'title.html')}} />
      <div dangerouslySetInnerHTML={{__html:get(slice.primary, 'text.html')}} />
      <div className='flex flex-col md:flex-row justify-evenly py-2'>
        {slice.items.map((counter, index) => {
          return (
            <div className='flex flex-col p-8 md:p-16 text-center leading-none'>
              <CountUp
                className='text-6xl font-light text-brand-dark-blue'
                delay={slice.primary.delay}
                end={counter.final_number}
                suffix={counter.suffix || ''}
                prefix={counter.prefix || ''}
                separator=','
                decimal='.'
                duration={slice.primary.duration}
              />
              <div dangerouslySetInnerHTML={{__html:get(counter, 'title.html')}} />
              <div dangerouslySetInnerHTML={{__html:get(counter, 'text.html')}} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Counter
