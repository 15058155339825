import React from 'react'
import PropTypes from 'prop-types'


const Code = ({ slice }) => {
  return (
    <div
      className='mx-auto max-w-4xl p-8 bg-gray-900 text-white rounded-lg overflow-hidden overflow-x-scroll text-sm'
      dangerouslySetInnerHTML={{__html:slice.primary.code.html}}
    />
  )
}

Code.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Code
