import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types'
import lottie from 'lottie-web';

const Animation = ({ animationName }) => {
  let animationContainer = createRef();
  const animationJSON = require(`./lottie/${animationName}.json`)
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationJSON
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, [animationJSON, animationContainer]); // no idea
  return (
    <div className='component-animation w-full h-auto' ref={animationContainer} />
  );
};


Animation.propTypes = {
  animationName: PropTypes.string.isRequired,
}

export default Animation;
