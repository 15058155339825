import React from 'react'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'

const Card = ({ fields }) => {
  return (
    <li className='w-full sm:w-1/2 md:w-1/3 flex flex-col'>
      <div className='component-card bg-white border border-gray-50 flex flex-col flex-grow shadow-lg relative transform transition duration-300 hover:scale-105 ease-in-out m-2'>
        {fields.image.fluid && (
          <div className='overflow-hidden transition duration-500 transform'>
            <Img fluid={fields.image.fluid} alt={fields.image.alt} />
          </div>
        )}
        <div className='flex flex-col flex-grow m-4'>
          <div className='text-brand-blue text-2xl leading-none font-semibold my-2' dangerouslySetInnerHTML={{__html:fields.title.text}} />
          {fields.text.text && (
            <div className='text-base my-3' dangerouslySetInnerHTML={{__html:fields.text.html}} />
          )}
          {fields.button_link.url && (
            <div className='mt-auto'>
              <CheckLink className='link block text-base text-brand-dark-blue font-semibold link' link={fields.button_link}>{fields.button_text} <span className='inline-block transform transition duration-300 hover:translate-x-full'>→</span></CheckLink>
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

export default Card
