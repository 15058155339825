import React from 'react'
import Card from './card'

const CardDeck = ({ slice }) => {
  var gridClass = 'lg:col-span-4'
  if (slice.primary.deck_title.text) {
    gridClass = 'lg:col-span-3'
  }

  return (
    <div className={`component-${slice.slice_type} grid grid-cols-2 lg:grid-cols-4 -mx-2`}>
      {slice.primary.deck_title.text && (
        <div className='deck-title title col-span-4 lg:col-span-1 flex flex-col justify-center'>
          <div dangerouslySetInnerHTML={{__html:slice.primary.deck_title.html}} />
        </div>
      )}
      <ul className={`col-span-4 flex flex-wrap ${gridClass}`}>
        {slice.items.map((card, index) => {
          return (
            <Card fields={card} key={`card-${index}`} />
          )
        })}
      </ul>
    </div>
  )
}

export default CardDeck
