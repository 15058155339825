import React from 'react'
import CheckLink from './checkLink'

const TextWithButton = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type}`}>
      <div dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
      {(slice.primary.button_link.url && slice.primary.button_text) && <div className='mt-5 md:mt-8 flex justify-center max-w-4xl mx-auto'><CheckLink className='button' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink></div>}
    </div>
  )
}

export default TextWithButton
