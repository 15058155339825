import React from 'react'
import PropTypes from 'prop-types'
import CheckLink from './checkLink'
import Img from 'gatsby-image'

const BannerWithCaption = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type} relative overflow-hidden shadow-lg border border-gray-50 bg-white`}>
      <div className='pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-1/2 lg:pb-24 xl:pb-32'>
        <div className='mt-10 mx-auto max-w-screen-xl px-6 sm:mt-12 sm:text-center md:mt-16 lg:mt-20 lg:text-left xl:mt-24'>
          {slice.primary.title.html && (
            <div dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
          )}
          {slice.primary.text.html && (
            <div className='mt-3 mb-3 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0' dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
          )}
          <div className='button-wrapper mt-5 md:mt-8 md:flex md:justify-center lg:justify-start'>
            <CheckLink className='button' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink>
          </div>
        </div>
      </div>
      <div className='lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 bg-gray-300'>
        <Img className='w-full h-full object-cover' fluid={slice.primary.image.fluid} alt={slice.primary.image.alt}/>
      </div>
    </div>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default BannerWithCaption
