import React from 'react'
import {loadStripe} from '@stripe/stripe-js'
import { graphql, StaticQuery } from 'gatsby'

const stripeQuery = graphql`
  {
    allStripePrice {
      edges {
        node {
          id
          active
          unit_amount
          currency
          recurring {
            interval
          }
        }
      }
    }
  }
`;

const DirectDebit = ({ customer, price }) => {
  const buyProduct = () => {
    return fetch('/.netlify/functions/stripe-createSession', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: price,
        customerId: customer
      })
    }).then(res => {
      console.log(res)
      return res.json();
    }).then(async result => {
      if (!result.sessionId) {
        throw new Error('There has been a Stripe Error')
      }
      const stripe = await loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);
      console.log('result', result)
      stripe.redirectToCheckout({
        sessionId: result.sessionId,
      })
    }).catch(function (e) {
      console.log(e)
      alert(e.message);
    });
  }
  if (!price) return null
  return (
    <StaticQuery
      query={`${stripeQuery}`}
      render={data => {
        if (!data.allStripePrice) return null
        const stripePriceData = data.allStripePrice.edges.find(stripePrice => stripePrice.node.id === price);
        if (!stripePriceData) return (
          <div className='text-center component-text text-xl'>Invalid Price, <a href='/contact'>contact us</a> for assistance</div>
        )
        const prettyPrice = (stripePriceData.node.unit_amount * 0.01).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return (
          <>
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">Direct Debit</h3>
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-5xl font-extrabold tracking-tight">{stripePriceData.node.currency === 'gbp' && '£'}{prettyPrice}</span>
                <span className="ml-1 text-xl font-semibold">/month</span>
              </p>
              <p className="mt-6 text-gray-500">Direct Debit is one of the safest and most convenient ways of paying your bills.</p>

              <ul role="list" className="mt-6 space-y-6">

                <li className="flex">
                  <svg className="flex-shrink-0 w-6 h-6 text-brand-dark-blue" x-description="Heroicon name: outline/check" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="ml-3 text-gray-500">Payments are made automatically.</span>
                </li>

                <li className="flex">
                  <svg className="flex-shrink-0 w-6 h-6 text-brand-dark-blue" x-description="Heroicon name: outline/check" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="ml-3 text-gray-500">It's guaranteed.</span>
                </li>

                <li className="flex">
                  <svg className="flex-shrink-0 w-6 h-6 text-brand-dark-blue" x-description="Heroicon name: outline/check" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="ml-3 text-gray-500">It saves you time.</span>
                </li>

              </ul>
            </div>
            <button
              className='button mt-8 block w-full py-3 px-6'
              onClick={buyProduct}
            >
              Pay by Direct Debit
            </button>
          </>

        )
      }}
    />
  )
}

export default DirectDebit
