import React from 'react'
import PropTypes from 'prop-types'

const Contact = ({ slice }) => {
  return (
    <div className='bg-white p-4 sm:p-8 shadow-md md:grid grid-cols-3 gap-4'>
      <form
        name='contact'
        method='POST'
        id='contactForm'
        action='/success/'
        data-netlify='true'
        data-netlify-honeypot='surname'
        className='col-span-2'
      >
        <input type='hidden' name='surname' />
        <input type='hidden' name='form-name' value='contact' />

        <div className='mb-4'>
          <label className='block text-gray-900 font-semibold text-xl tracking-tighter mt-5 mb-3' htmlFor='name'>What's your name?</label>
          <input className='shadow appearance-none border rounded w-full py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:ring' type='text' name='name' id='name' required />
        </div>
        <div className='mb-4'>
          <label className='block text-gray-900 font-semibold text-xl tracking-tighter mt-5 mb-3' htmlFor='email'>What's your email address?</label>
          <input className='shadow appearance-none border rounded w-full py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:ring' type='email' name='email' id='email' required />
        </div>
        <div className='mb-4'>
          <label className='block text-gray-900 font-semibold text-xl tracking-tighter mt-5 mb-3' htmlFor='message'>How can we help?</label>
          <textarea className='shadow appearance-none border rounded w-full py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:ring' name='message' id='message' required></textarea>
        </div>
        <input type='submit' className='button cursor-pointer' value='Send your enquiry' />
      </form>
      <div className='py-4 sm:pt-0 sm:px-2 md:px-8 component-text'>
        {slice.items[0].text.html && (
          <div className='mt-3 mb-3 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0' dangerouslySetInnerHTML={{__html:slice.items[0].text.html}} />
        )}
      </div>
    </div>
  )
}

Contact.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Contact
